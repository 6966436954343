<template>
  <div class="distro-page max-container">
    <div class="distro-page-inner">
      <DistroInfo />
      <BrandListItem />
    </div>
  </div>
</template>

<script>
import DistroInfo from "./modules/DistroInfo.vue";
import BrandListItem from "./modules/BrandListItem.vue";

export default {
  name: "DistroPage",
  components: {
    DistroInfo,
    BrandListItem,
  },
};
</script>

<style lang="scss" scoped>
.distro-page {
  @apply w-full pb-10;

  .distro-page-inner {
    @apply lg:w-full space-y-7;
  }
}
</style>
