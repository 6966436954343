<template>
  <div class="distro-info">
    <div class="brand-info">
      <div class="b-logo">
        <img src="/images/brand2.png" alt="" />
      </div>
      <div class="info-data">
        <p>{{ company.data.name }} <span>ORDER FORM</span></p>
        <span>Customer since {{ moment(company.data.created_at) }}</span>
      </div>
    </div>
    <div>
      <button @click="!isCollapsedAll ? onCollapseAll() : onExpandAll()">{{ !isCollapsedAll ? "Collapse" : "Expand" }} All</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Distribution from "@/modules/Distribution";

export default {
  name: "DistroInfo",
  methods: {
    moment: function(date) {
      return moment(date).format("MMMM YYYY");
    },
  },
  setup() {
    const isCollapsedAll = ref(false);
    const { company, company_details } = Distribution();
    company_details();

    const onCollapseAll = () => {
      document.querySelectorAll('.disc-btn[data-headlessui-state="open"]').forEach((b) => {
        b.click();
      });
      isCollapsedAll.value = true;
    };

    const onExpandAll = () => {
      document.querySelectorAll('.disc-btn[data-headlessui-state][aria-expanded="false"]').forEach((b) => {
        b.click();
      });
      isCollapsedAll.value = false;
    };

    return {
      onCollapseAll,
      isCollapsedAll,
      onExpandAll,
      company,
    };
  },
};
</script>

<style lang="scss" scoped>
.distro-info {
  @apply w-full flex items-center p-4 rounded-lg text-white justify-between;
  background: #474557;

  .brand-info {
    @apply flex space-x-4;

    .b-logo {
      @apply h-12 w-12 flex items-center justify-center bg-white rounded-md flex-shrink-0;

      img {
        max-width: calc(100% - 6px);
        max-height: calc(100% - 6px);
      }
    }
  }

  .info-data {
    @apply flex flex-col;

    p {
      @apply text-base font-normal text-white uppercase;
    }

    span {
      @apply text-sm text-white text-opacity-70;
    }
  }

  button {
    @apply text-sm font-normal text-white px-4 py-1.5 uppercase;
  }
}
</style>
