<script setup>
import { ref, onBeforeMount, onMounted } from "vue";
import Sorting from "./Sorting.vue";
import ProductRow from "./ProductRow.vue";
import ProductInfoModal from "./ProductInfoModal.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { AdjustmentsIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import Distribution from "@/modules/Distribution";
import { useStore } from "vuex";
import BrandsListLoader from "../../../../components/loaders/BrandsListLoader.vue";

const isInfoModalVisible = ref(false);
const currentProdSlug = ref("");
const isBrandsAndCategoriesLoading = ref(false);

const openInfoModal = (slug) => {
  currentProdSlug.value = slug;
  isInfoModalVisible.value = true;
  console.log(slug);
};

const { getBrands, brands, getCategories, categories, getProducts, products } = Distribution();

const store = useStore();
onBeforeMount(() => {
  store.dispatch("getCart", { ax: null });
});

const getImage = (brand) => {
  return process.env.BASE_URL_IMG + "/" + brand.image;
};

const getBrandsAndCategories = async () => {
  if (isBrandsAndCategoriesLoading.value) return;
  try {
    isBrandsAndCategoriesLoading.value = true;
    await getBrands();
    const categoriesPromises = brands.data.map((b) => getCategories(b));
    await Promise.allSettled(categoriesPromises);
    const productsPromises = [];
    if (brands.data.length) {
      const firstBrand = brands.data[0];
      const firstCategory = categories.data[firstBrand.id][0];
      if (firstCategory.id) productsPromises.push(getProducts(firstBrand.id, firstCategory.id));
      await Promise.allSettled(productsPromises)
        .then((res) => console.log(res))
        .finally(() => {
          isBrandsAndCategoriesLoading.value = false;
        });
    } else {
      isBrandsAndCategoriesLoading.value = false;
    }
  } catch (e) {
    console.log(e);
  }
};

onMounted(() => {
  getBrandsAndCategories();
});

// const onRemoveFilter = (brand, filter) => {
//   brand.categoryFilters = brand.categoryFilters.filter((r) => r.id != filter.id);
// };
</script>

<template>
  <div class="brand-list-item">
    <template v-if="isBrandsAndCategoriesLoading">
      <BrandsListLoader v-for="x in 3" :key="x" />
    </template>
    <template v-else>
      <div class="flex flex-col border border-gray-200 rounded-md overflow-visible" v-for="(b, bIdx) in brands.data" :key="b.id">
        <Disclosure v-slot="{ open }" :defaultOpen="true">
          <div class="brand-item-inner">
            <div class="brand-item-info">
              <div class="item-logo">
                <img :src="getImage(b)" :alt="b.name" />
              </div>
              <div class="item-data">
                <p>{{ b.name }}</p>
                <div class="meta">
                  <span>{{ categories.data[b.id].length }} {{ categories.data[b.id].length > 1 ? "Categories" : "Category" }}</span>
                  <span>•</span>
                  <span>{{ b.active_product_count }} {{ b.active_product_count > 1 ? "Products" : "Product" }}</span>
                </div>
              </div>
            </div>
            <div class="other-actions flex items-center space-x-4">
              <!-- <input type="text" name="search" id="search" placeholder="Search products.." class="brand-search" />
            <Sorting />
            <Popover as="div" class="w-full sm:w-auto relative inline-block text-left">
              <PopoverButton
                class="border border-gray-200 w-full sm:w-36 relative inline-flex items-center justify-between py-1.5 pl-4 pr-4 text-left text-sm font-normal bg-white cursor-pointers text-brand-secondary focus:outline-none rounded"
              >
                <AdjustmentsIcon class="flex-shrink-0 h-4 w-4 mr-2 text-brand-body" aria-hidden="true" />
                <span>{{ brand.categoryFilters.length ? brand.categoryFilters.length + " Categories" : "Category" }}</span>
                <ChevronDownIcon class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-brand-body" aria-hidden="true" />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <PopoverPanel
                  class="w-80 origin-top-right absolute right-0 mt-2 bg-white rounded shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                >
                  <div class="space-y-3 p-3 border-b border-brand-ash border-opacity-60">
                    <form class="relative w-full" autocomplete="off">
                      <input
                        id="searchcat"
                        v-model="brand.categoriesSearchQuery"
                        name="search-cat"
                        class="appearance-none block text-xs font-light w-full pl-5 pr-3 py-1.5 border border-none rounded-full leading-5 bg-brand-ash bg-opacity-20 placeholder-text focus:outline-none sm:text-sm focus:ring-brand-smoke"
                        placeholder="Search Categories"
                        type="search"
                        autocomplete="chrome-off"
                      />
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <SearchIcon class="h-4 w-4 text-brand-ash" aria-hidden="true" />
                      </div>
                    </form>
                    <div class="-m-1 flex flex-wrap items-center" v-if="brand.categoryFilters.length">
                      <span
                        v-for="activeFilter in brand.categoryFilters"
                        :key="activeFilter.id"
                        class="m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-xs font-medium bg-white text-brand-body"
                      >
                        <span>{{ activeFilter.name }}</span>
                        <button
                          type="button"
                          class="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                          @click="onRemoveFilter(brand, activeFilter)"
                        >
                          <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="space-y-4 p-4 max-h-25 overflow-auto">
                    <div
                      v-for="option in brand.categoriesSearchQuery
                        ? categories.filter((r) => r.name.toLowerCase().search(qryC.toLowerCase()) >= 0)
                        : categories"
                      :key="option.id"
                      class="flex items-center justify-between"
                    >
                      <div>
                        <input
                          :id="`cat-${option.id}`"
                          name="catfilter"
                          v-model="brand.categoryFilters"
                          :value="option"
                          type="checkbox"
                          class="h-4 w-4 border-2 border-brand-secondary opacity-60 rounded text-brand-secondary focus:ring-transparent"
                        />
                        <label
                          :for="`cat-${option.id}`"
                          class="ml-3 pr-6 text-sm font-medium text-brand-secondary text-opacity-70 whitespace-nowrap"
                        >
                          {{ option.name }}
                        </label>
                      </div>
                      <span class="text-xs text-brand-body text-opacity-50">{{ option.stock }}</span>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover> -->
              <button class="download-btn">
                <Icon name="document-file" class="w-3.5 h-3.5 sm:w-4 sm:h-4 -mt-0.5" />
                <span>Brand Media / Sell Sheets</span>
              </button>
              <DisclosureButton class="disc-btn" @click="getCategories(b)">
                <template v-if="categories.loading[b.id]">
                  <Spinner className="w-6 h-6" :loading="true" />
                </template>
                <template v-else>
                  <Icon
                    class="w-6 h-6 transform transition-transform text-theme-body"
                    name="down-arrow"
                    :class="open ? 'rotate-180' : ''"
                  />
                </template>
              </DisclosureButton>
            </div>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <DisclosurePanel as="div" class="brand-list">
              <Disclosure v-for="(c, idx) in categories.data[b.id]" :key="c.id" :defaultOpen="bIdx == 0 && idx == 0" v-slot="{ open }">
                <div class="variant-list">
                  <DisclosureButton class="variant-header" @click="getProducts(b.id, c.id)">
                    <p class="flex items-center space-x-2">
                      <Icon name="all-categories" />
                      <span class="pt-0.5">{{ c.name }}</span>
                    </p>
                    <template v-if="products.loading[`${b.id}-${c.id}`]">
                      <Spinner className="h-6 w-6" :loading="true" />
                    </template>
                    <template v-else>
                      <Icon
                        class="w-6 h-6 transform transition-transform text-theme-body"
                        name="down-arrow"
                        :class="open ? 'rotate-180' : ''"
                      />
                    </template>
                  </DisclosureButton>
                  <Transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    v-if="products.data[b.id]"
                  >
                    <DisclosurePanel as="div">
                      <table class="w-full">
                        <tr>
                          <th class="px-5 py-2.5 text-sm font-light text-brand-body text-left border-b border-t border-gray-100">
                            <span>Product</span>
                          </th>
                          <th class="px-5 py-2.5 text-sm font-light text-brand-body text-left pl-20 border-b border-t border-gray-100">
                            <span>Variant</span>
                          </th>
                          <th class="px-5 py-2.5 text-sm font-light text-brand-body text-left border-b border-t border-gray-100">
                            <span>Price</span>
                          </th>
                          <th class="px-5 py-2.5 text-sm font-light text-brand-body text-left border-b border-t border-gray-100">
                            <span>Wholesale Price</span>
                          </th>
                          <th class="px-5 py-2.5 text-sm font-light text-brand-body text-left border-b border-t border-gray-100">
                            <span>Last Ordered</span>
                          </th>
                          <th class="px-5 py-2.5 text-sm font-light text-brand-body text-left border-b border-t border-gray-100"></th>
                        </tr>
                        <template v-if="products.loading[`${b.id}-${c.id}`]">
                          <tr>
                            <td colspan="10">
                              <BrandsListLoader v-for="y in 3" :key="y" />
                            </td>
                          </tr>
                        </template>
                        <template v-for="p in products.data[b.id][c.id]" :key="p.id">
                          <ProductRow
                            v-for="(v, i) in p.variants"
                            :key="v.id"
                            :index="i"
                            :product="p"
                            :variant="v"
                            @onProductClick="openInfoModal"
                          />
                          <tr>
                            <td colspan="10" class="border-t border-gray-100" />
                          </tr>
                        </template>
                      </table>
                    </DisclosurePanel>
                  </Transition>
                </div>
              </Disclosure>
            </DisclosurePanel>
          </transition>
        </Disclosure>
      </div>
    </template>

    <ModalFull
      :is-visible="isInfoModalVisible"
      :reset-container="true"
      container-class="w-full max-w-5xl z-maximum"
      @close="isInfoModalVisible = false"
    >
      <div class="product-info-container">
        <ProductInfoModal :productSlug="currentProdSlug" @close="isInfoModalVisible = false" />
      </div>
    </ModalFull>
  </div>
</template>

<style lang="scss" scoped>
.product-info-container {
  @apply w-full  overflow-y-auto bg-theme-bg rounded-lg p-4 relative text-left;
  min-height: 200px;
  max-height: 80vh;
  @include CssScrollbar();
}

.brand-search {
  @apply w-full max-w-lg text-sm py-1.5 focus:ring-transparent focus:outline-none  border border-gray-300 bg-white bg-opacity-70 text-brand-secondary placeholder-gray-400 rounded hover:border-indigo-500 transition-colors;
}
.brand-actions {
  @apply flex items-center justify-between mb-8;
}
.brand-list-item {
  @apply w-full flex flex-col gap-8;

  .brand-item-inner {
    @apply w-full flex items-center px-5 py-3 rounded-md text-brand-body justify-between bg-white;
  }
  .brand-item-info {
    @apply flex space-x-4 items-center;

    .item-logo {
      @apply h-12 w-12 flex items-center justify-center bg-white rounded-md flex-shrink-0 border border-brand-ash;

      img {
        max-width: calc(100% - 6px);
        max-height: calc(100% - 6px);
      }
    }
    .item-data {
      @apply flex flex-col items-start;

      p {
        @apply text-base font-normal text-brand-primary leading-none mb-1;
      }

      .meta {
        @apply flex space-x-1.5;
        span {
          @apply text-sm text-brand-body leading-none font-normal;
        }
      }
    }
  }

  .brand-list {
    @apply flex flex-col w-full bg-white;

    .header-item {
      @apply px-5 py-1.5 text-sm font-light text-brand-body;

      span {
        @apply text-sm font-light text-brand-body;
      }
    }

    .varinat-item-cell {
      @apply px-5 py-1.5 flex items-center flex-shrink-0;
    }

    .list-header {
      @apply flex items-stretch border-t border-b border-theme-border border-opacity-60;

      .header-item {
        @apply flex-shrink-0;
      }

      .category {
        @apply w-full;
        max-width: 180px;
      }

      .item {
        @apply w-full pl-20;
        max-width: 320px;
      }

      .price {
        @apply w-full;
        max-width: 150px;
      }

      .last-ordered {
        @apply w-full;
        max-width: 300px;
      }
    }
    .variant-list {
      @apply flex flex-col;

      .variant-header {
        @apply flex items-center justify-between px-5 py-3 border-t;
        background: #fcfbff;
        border-color: #f0ebff;
        p {
          @apply text-base font-normal;
          color: #4a55b8;
        }
      }
    }

    .variant-item {
      @apply flex items-stretch py-2 border-b border-brand-ash border-opacity-60;

      &:last-child {
        @apply border-0;
      }
      .category {
        @apply w-full flex flex-col leading-none items-start;
        max-width: 180px;

        p {
          @apply text-sm text-theme-secondary;
        }

        span {
          @apply text-xs text-theme-light uppercase;
        }
      }

      .item {
        @apply w-full  space-x-4;
        max-width: 320px;

        .item-img {
          @apply w-12 h-12 border border-theme-border border-opacity-60 rounded bg-white flex-shrink-0 cursor-pointer;
          img {
            @apply w-full h-auto;
          }
        }

        .item-name {
          @apply text-sm text-theme-secondary flex-grow cursor-pointer hover:underline;
          min-width: 150px;
        }

        .stock-info {
          @apply text-xxs text-brand-gray uppercase flex-shrink-0;
          width: 120px;
        }
      }

      .price {
        @apply w-full;
        max-width: 150px;

        p {
          @apply text-sm text-theme-secondary flex-grow;
        }
      }

      .last-ordered {
        @apply w-full flex-grow;
        max-width: 300px;

        span {
          @apply text-sm text-theme-body;
          min-width: 120px;
        }
        .order-badge {
          @apply text-xs font-normal min-w-0 flex-grow-0 inline-flex text-center items-center justify-center w-auto rounded-full px-5 py-2 leading-none;
          background: rgba(156, 228, 144, 0.31);
          color: #41b871;

          svg {
            @apply w-3.5 h-3.5 mr-1;
          }
        }
      }

      .rest-info {
        @apply flex-grow flex justify-end space-x-5;

        .qty-field {
          @apply flex justify-end;
          min-width: 150px;
        }
      }
    }
  }
}
.notify-bell {
  @apply flex items-center space-x-3 uppercase text-xs tracking-tight;
  .n-icon {
    @apply inline-flex items-center justify-center w-8 h-8 bg-brand-red text-white rounded;

    svg {
      @apply w-4 h-4;
    }
  }
  &.undo {
    .n-icon {
      @apply bg-theme-secondary;
    }
  }
}

.download-btn {
  @apply w-auto flex-shrink-0 mr-5 inline-flex items-center text-xxs text-white bg-theme-secondary hover:bg-opacity-95 space-x-0.5 sm:space-x-1 tracking-wide px-2.5 py-2 rounded leading-none border border-theme-secondary hover:text-white transition-colors;
}
</style>
