<template>
  <Listbox v-model="selectedOption">
    <div class="filter-listing">
      <ListboxButton class="filter-listing-btn">
        <Icon name="sort" class="w-4 h-4 mr-2" />
        <span class="block truncate text-brand-secondary">{{ selectedOption.name }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="w-5 h-5 text-theme-body " aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="filter-listing-container">
          <ListboxOption v-slot="{ active, selected }" v-for="opt in optionsList" :key="opt.name" :value="opt" as="template">
            <li :class="[selected || active ? 'active' : '']">
              <p :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">
                {{ opt.name }}
              </p>
              <span v-if="opt.description">{{ opt.description }}</span>
              <span v-if="selected" class="check-mark">
                <CheckIcon aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

const defaultOptions = [
  {
    name: "Best Selling",
    value: "best-selling",
    description: '"Best Selling" are products with the highest quantity of sales',
  },
  {
    name: "Alphabetical Sort",
    value: "name",
    description: "Products sorted from A to Z",
  },
  {
    name: "Lowest Price",
    value: "price-low",
    description: "Products sorted by the lowest price first",
  },
  {
    name: "Highest Price",
    value: "price-high",
    description: "Products sorted by the highest price first",
  },
];

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => defaultOptions,
    },
  },
  emits: ["onSort"],
  setup(props, { emit }) {
    const selectedOption = ref({});
    const optionsList = ref([]);

    onMounted(() => {
      optionsList.value = props.options;
      selectedOption.value = optionsList.value[0];
    });

    watch(selectedOption, (selected) => {
      if (selected.value) {
        emit("onSort", selected.value);
      }
    });

    return {
      selectedOption,
      optionsList,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-listing {
  @apply md:flex relative hidden items-center space-x-4 flex-shrink-0;
}
.filter-listing-container {
  @apply absolute top-full right-0 mt-1 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50 divide-y divide-gray-100 max-h-44;
  min-width: 280px;
  @include CssScrollbar();

  li {
    @apply flex flex-col select-none relative py-3 pr-10 pl-4 text-brand-primary cursor-pointer;
    &.active {
      @apply text-brand-primary bg-brand-smoke;
    }
    p {
      @apply text-md;
    }
    span {
      @apply text-xs  text-theme-body font-normal;
      &.check-mark {
        @apply absolute inset-y-0 right-0 flex items-start pt-3 justify-center pr-3 text-brand;
        svg {
          @apply w-5 h-5;
        }
      }
    }
  }
}
.filter-listing-btn {
  @apply flex items-center flex-shrink-0 relative py-1.5 pl-3 pr-8 text-left font-normal bg-white bg-opacity-50 border border-gray-200 rounded cursor-pointer focus:outline-none text-xs sm:text-sm;
  min-width: 200px;

  &.disabled {
    @apply cursor-default;
  }
}
.filter-listing-label {
  @apply flex items-center text-xs md:text-sm font-light uppercase tracking-wide text-brand-secondary;
  svg {
    @apply w-5 h-5 mr-2;
  }
}
</style>
