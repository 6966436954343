import { inject, reactive, ref } from "vue";

export default function Distribution() {
  const axios = inject("axios");

  const company = reactive({
    data: [],
    loading: false,
  });

  const brands = reactive({
    data: [],
    loading: false,
  });

  const products = reactive({
    data: {},
    loading: {},
  });
  const categories = reactive({
    data: {},
    loading: {},
  });

  const company_details = () => {
    company.loading = true;
    axios.authApi.get("distributor/company").then((res) => {
      company.data = res.data.data;
    });
  };

  const getProducts = (brand_id, category_id) => {
    if (!products.data[brand_id]) {
      products.data[brand_id] = {};
    }

    products.loading[`${brand_id}-${category_id}`] = true; // Set loading to true

    if (!products.data[brand_id][category_id]) {
      return axios.authApi
        .get("distributor/products", {
          params: {
            brand_id: brand_id,
            category_id: category_id,
          },
        })
        .then((res) => {
          products.data[brand_id][category_id] = res.data.data.data;
        })
        .finally(() => {
          products.loading[`${brand_id}-${category_id}`] = false; // Set loading to false once the request is completed
        });
    } else {
      products.loading[`${brand_id}-${category_id}`] = false; // Set loading to false if data already exists
    }
  };

  const getBrands = () => {
    brands.loading = true;
    return axios.authApi
      .get("distributor/brands")
      .then((res) => {
        brands.data = res.data.data;
      })
      .finally(() => {
        brands.loading = false;
      });
  };

  const getCategories = (brand) => {
    if (!categories.data[brand.id]) {
      categories.loading[brand.id] = true;
      return axios.authApi
        .get("distributor/categories/" + brand.slug)
        .then((res) => {
          categories.data[brand.id] = res.data.data;
        })
        .finally(() => {
          categories.loading[brand.id] = false;
        });
    }
  };

  return {
    company,
    company_details,
    getProducts,
    products,
    getCategories,
    categories,
    getBrands,
    brands,
  };
}
