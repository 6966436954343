<template>
  <div class="w-full flex items-center px-5 py-3 rounded-md text-brand-body justify-between bg-white border border-gray-100">
    <div class="flex space-x-4 items-center">
      <div class="h-12 w-12 bg-gray-100 animate-pulse rounded-md" />
      <div class="flex flex-col items-start ">
        <p class="text-base font-normal text-brand-primary leading-none mb-1 loader-block"><em>XXXXXX XXX XXXX XXXX XXXX</em></p>
        <span class="loader-block"><em>XXXXXX</em></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandListLoader",
};
</script>
<style lang="scss" scoped>
.loader-block {
  em {
    @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-md text-sm;
  }
}
</style>
